import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 dark:bg-gray-900 text-white dark:text-gray-300 py-6 transition-colors duration-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center">
        {/* Footer inset-inline-start: Copyright Notice */}
        <div className="text-center sm:text-left mb-4 sm:mb-0">
          <p className="font-roboto text-[clamp(0.75rem, 0.9vw + 0.2rem, 1rem)] lg:text-sm xl:text-xs leading-relaxed text-gray-300 dark:text-gray-300">
            &copy; {new Date().getFullYear()} @Medicup. All rights reserved.
          </p>
        </div>

        {/* Footer Links */}
        <div className="flex justify-center sm:justify-end space-x-4">
          <a
            href="#"
            className="font-roboto text-[clamp(0.75rem, 0.9vw + 0.2rem, 1rem)] lg:text-sm xl:text-xs hover:underline text-gray-400 hover:text-white dark:hover:text-gray-100 transition-colors duration-300"
          >
            Privacy Policy
          </a>
          <a
            href="#"
            className="font-roboto text-[clamp(0.75rem, 0.9vw + 0.2rem, 1rem)] lg:text-sm xl:text-xs hover:underline text-gray-400 hover:text-white dark:hover:text-gray-100 transition-colors duration-300"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;